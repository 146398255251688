<script>
  import { formatPercentage } from '../lib/utils'

  export let value = 0

  function getPrefix (v) {
    if (v > 0) return '+'
    if (v < 0) return '–'
    return '±'
  }
</script>

<span class:has-text-success={value > 0} class:has-text-danger={value < 0}>
  {getPrefix(value)}{formatPercentage(Math.abs(value) / 100, 1, 1)}
</span>
