<script>
  export let icon = 'frown'
  export let text = 'An error occured'
  export let error = null
  export let borderless = false

  import MiddleBox from './MiddleBox.svelte'
  import { RouterLink } from '../router'
  import { Icon } from 'svelma-fixed'
  import { formatError } from '../lib/utils'

  $: if (error) console.error(error)
</script>

<MiddleBox {borderless} class="has-text-centered notification is-danger">
  <p class="m-3"><Icon {icon} size="is-large" /></p>
  <h1 class="title">{text}</h1>
  <h2 class="subtitle">
    <slot />
    {#if error}
      {formatError(error)}
    {/if}
  </h2>
  {#if error}
    <RouterLink to={{ name: 'HOME' }} cls="button is-danger is-inverted is-outlined">Back to Homepage</RouterLink>
  {/if}
</MiddleBox>
