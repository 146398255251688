<script context="module">
  const cachedCollections = new Map() // For navigation
</script>

<script>
  import AutoComplete from 'simple-svelte-autocomplete'
  import { apiCall } from '../lib/api'
  import { fly, slide } from 'svelte/transition'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import uri from 'uri-tag'
  import FancyLoader from '../components/FancyLoader.svelte'
  import CollectionDetails from '../components/CollectionDetails.svelte'
  import { getAddressShortLabel } from '../lib/utils'
  import { softNavigate } from '../router'
  import { createLoadingStore } from '../stores/loading'
  import { Icon, Dialog } from 'svelma-fixed'
  import CollectionCard from '../components/CollectionCard.svelte'

  export let addressFromUrl = null
  let prevAddressFromUrl = null
  let selectedCollection

  const loadingAddressFromUrl = createLoadingStore()
  const loadingIntermediateResults = createLoadingStore()
  $: if (addressFromUrl !== prevAddressFromUrl && !$loadingIntermediateResults && !$loadingAddressFromUrl) {
    prevAddressFromUrl = addressFromUrl
    selectedCollection = null
    if (addressFromUrl) {
      if (cachedCollections.has(addressFromUrl)) {
        selectedCollection = cachedCollections.get(addressFromUrl)
      } else {
        loadingAddressFromUrl(async () => {
          try {
            const [result] = await searchCollections(addressFromUrl)
            if (!result) throw new Error('The provided collection was not found!')

            selectedCollection = result
            cachedCollections.set(addressFromUrl, result)
          } catch (e) {
            console.error(e)
            Dialog.alert({
              title: 'Error',
              message: e.serverErrorMessage ?? e.message,
              type: 'is-warning'
            })
          }
        })
      }
    }
  }

  async function searchCollections (search) {
    if (!search.trim()) return []

    return await apiCall('GET', '/api/collections', { search })
  }

  async function loadIntermediateResults (collection) {
    return await loadingIntermediateResults(async () => {
      if (!collection) throw new Error('No collection selected')

      if (addressFromUrl !== collection.address) {
        prevAddressFromUrl = addressFromUrl = collection.address
        softNavigate({ name: 'collection', params: { address: collection.address } })
      }

      cachedCollections.set(collection.address, collection)

      if (collection.bannerImageUrl) new Image().src = collection.bannerImageUrl // Preload banner image

      return await apiCall('GET', uri`/api/collections/${collection.address}`)
    })
  }

  async function loadPopularCollections () {
    return await apiCall('GET', '/api/collections/popular')
  }
</script>

<svelte:head>
	<title>Tailor</title>
</svelte:head>

<style lang="scss">
  .huge-text {
    font-size: 200%;

    // These styles are needed to fix positioning of the dropdown arrow/loading spinner with changed font size
    :global(.autocomplete) {
      height: 1.6em !important;
    }

    :global(.autocomplete:not(.is-loading))::after {
      top: 0.75em !important;
      right: 0.85em !important;
    }

    :global(.select.is-loading)::after {
      top: 0.275em !important;
    }
  }

  .autocomplete-item {
    display: grid;

    grid-template-columns: 64px minmax(0, 1fr); // minmax(0, 1fr) is needed to make the second column shrinkable (otherwise it will overflow as 1fr expands to minmax(auto, 1fr))
    gap: 1em;

    .collection-image {
      grid-column: 1;
      width: 64px;
      height: 64px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .collection-label {
      grid-column: 2;
      font-size: 80%;

      display: flex;
      flex-direction: column;

      > * {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      span {
        line-height: 1.2;
      }

      small {
        line-height: 1;
        font-size: 60%;
        opacity: 0.8;
      }
    }
  }

  .collection-card-link {
    transition: transform 0.2s ease-in-out;
    display: block;

    &:hover {
      transform: scale(1.05);
    }
  }
</style>

<div class="container">
  <div class="box my-4">
    {#if $loadingAddressFromUrl}
      <FancyLoader asHeader />
    {:else}
      {#if !selectedCollection}
        <h2 class="title is-2 my-0 has-text-centered" transition:slide|local>
          <div class="pt-5">
            Get a List of Owners for Any Ethereum NFT Collection
          </div>
        </h2>
      {/if}

      <div class="columns mb-5 mt-2">
        <div class="column is-8 is-offset-2 huge-text">
          <AutoComplete
            searchFunction={searchCollections}
            delay={300}
            localFiltering={false}
            placeholder="Search for a collection name or enter contract address"
            labelFieldName="name"
            valueFieldName="address"
            showLoadingIndicator
            inputClassName="is-rounded"
            bind:selectedItem={selectedCollection}
          >
            <div slot="item" let:item class="autocomplete-item">
              <div class="collection-image">
                <img src={item.imageUrl ?? '/images/nft_placeholder.png'} alt="NFT icon" />
              </div>
              <div class="collection-label">
                <span>
                  {item.name}
                  {#if item.verified}
                    <span class="has-text-info" style:position="relative" style:top="-0.15em">
                      <Icon icon="check-circle" />
                    </span>
                  {/if}
                </span>
                <small>{item.description}</small>
                <small>{getAddressShortLabel(item.address)}</small>
              </div>
            </div>
          </AutoComplete>
        </div>
      </div>

      {#if selectedCollection}
        {#await loadIntermediateResults(selectedCollection)}
          <FancyLoader asHeader />
        {:then collection}
          <CollectionDetails {collection} intermediate />
        {:catch error}
          <ErrorDisplay {error} />
        {/await}
      {:else}
        {#await loadPopularCollections()}
          <FancyLoader asHeader />
        {:then collections}
          <div class="columns is-multiline">
            {#each collections as collection, i (collection.address)}
              <div class="column is-6 is-12-mobile" in:fly={{ y: 100, duration: 200, delay: 100 * i }}>
                <a class="collection-card-link" href={uri`/collections/${collection.address}`} on:click|preventDefault={() => (selectedCollection = collection)}>
                  <CollectionCard {collection} />
                </a>
              </div>
            {/each}
          </div>
        {:catch error}
          <ErrorDisplay {error} />
        {/await}
      {/if}
    {/if}
  </div>
</div>
