<style lang="scss">
  .content {
    & > * {
      vertical-align: middle;
    }
  }
</style>

<footer class="footer">
  <div class="content container has-text-centered">
    <p class="m-0">&copy; {new Date().getFullYear()} - All rights reserved</p>
  </div>
</footer>
