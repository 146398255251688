<script>
  import pluralize from 'pluralize'
  import Tooltip from './Tooltip.svelte'
  import { Icon } from 'svelma-fixed'
  import { formatCurrency } from '../lib/utils'
  import PercentageChange from './PercentageChange.svelte'

  export let collection
</script>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  @include mobile {
    .image {
      width: 64px;
      height: 64px;
    }
  }
</style>

<div class="card">
  <div class="card-content">
    <div class="media">
      <div class="media-left">
        <figure class="image is-128x128">
          <img src="{collection.imageUrl ?? '/images/nft_placeholder.png'}" alt="Collection icon">
        </figure>
      </div>
      <div class="media-content">
        <p class="title is-3">
          {collection.name}
          {#if collection.verified}
            <Tooltip label="Verified collection">
              <span class="has-text-info" style:position="relative" style:top="-0.15em">
                <Icon icon="check-circle" />
              </span>
            </Tooltip>
          {/if}
        </p>
        <p class="subtitle is-5"><strong><Icon icon="users" /> {collection.totalOwnersCount.toLocaleString('en-US')}{collection.totalIsMoreThanCount ? '+' : ''} {pluralize('owner', collection.totalOwnersCount)}</strong><br />
          <Icon icon="search-dollar" /> Market cap {formatCurrency(collection.tradingData.marketCapUsd, 'USD', undefined, undefined, true)} <small>(<PercentageChange value={collection.tradingData.marketCapUsdPercentageChange} />)</small><br />
          <Icon icon="chart-line" /> 24h volume {formatCurrency(collection.tradingData.volumeUsd, 'USD', undefined, undefined, true)} <small>(<PercentageChange value={collection.tradingData.volumeUsdPercentageChange} />)</small>
        </p>
      </div>
    </div>
  </div>
</div>
