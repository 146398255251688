'use strict'

// https://spaceavocado.github.io/svelte-router/
import createRouter from '@spaceavocado/svelte-router'
import { get } from 'svelte/store'
import { appInfo } from './lib/appInfo'
import { onboard } from './stores/onboard'

export { default as RouterView } from '@spaceavocado/svelte-router/component/view'
export { default as RouterLink } from '@spaceavocado/svelte-router/component/link'

// View components
import View404 from './views/View404.svelte'
import ViewHome from './views/ViewHome.svelte'
import ViewOrder from './views/ViewOrder.svelte'
import ViewBetaLogin from './views/ViewBetaLogin.svelte'

import { Toast } from 'svelma-fixed'

export const router = createRouter({
  activeClass: 'is-active',
  routes: [
    {
      path: '/',
      name: 'HOME',
      component: ViewHome,
      meta: { hideAccountCenter: true }
    },
    {
      path: '/collections/:address',
      name: 'collection',
      component: ViewHome,
      props: route => ({ addressFromUrl: route.params.address }),
      meta: { hideAccountCenter: true }
    },
    {
      path: '/orders/:orderId',
      name: 'order',
      component: ViewOrder,
      props: route => ({ orderId: route.params.orderId }),
      meta: { hideAccountCenter: true }
    },
    {
      path: '/beta-login',
      name: 'betaLogin',
      component: ViewBetaLogin,
      meta: { hideNav: true, hideAccountCenter: true }
    },
    {
      path: '*',
      component: View404,
      meta: { hideAccountCenter: true }
    }
  ]
})

export function checkAuth (route = get(router).currentRoute, next = route => { if (route) get(router).push(route) }) {
  if (appInfo.incorrectBetaPassword && route && !route?.meta?.hideNav) {
    console.log('Redirecting to beta login page')
    return next({ name: 'betaLogin' })
  }

  next()
}

get(router).navigationGuard((from, to, next) => {
  console.log('Navigation attempt:', from, to)
  if (appInfo.initialized) checkAuth(to, next)
})

get(router).onError(error => {
  Toast.create({ message: error, type: 'is-danger' })
  console.error('Router error', error)
})

get(router).onNavigationChanged((from, to) => {
  const hideAccountCenter = !!to?.meta?.hideAccountCenter
  if (onboard.state.get().accountCenter.enabled !== !hideAccountCenter) {
    onboard.state.actions.updateAccountCenter({
      enabled: !hideAccountCenter
    })
  }
})

export let shouldFixRouter = true

// This can be used to update props and route without reinstantiating component
export function softNavigate (route, operation = 'push') {
  try {
    shouldFixRouter = false
    if (route) get(router)[operation](route)
  } finally {
    setTimeout(() => {
      shouldFixRouter = true
    }, 0)
  }
}
