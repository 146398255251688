<script>
  import { connectWallet, onboardState, restoreWalletConnection, walletConnecting } from '../stores/onboard'
  import { Button } from 'svelma-fixed'

  restoreWalletConnection()
</script>

{#if !$onboardState.wallets.length}
  <Button size="is-small" outlined type="is-primary" iconLeft="wallet" on:click={() => connectWallet()} loading={$walletConnecting}>Connect Wallet</Button>
{/if}
