<script>
  export let title = 'An error occured'
  export let error = null
  export let type = 'is-danger'

  import { Notification } from 'svelma-fixed'
  import { formatError } from '../lib/utils'

  $: if (error) console.error(error)
</script>

<Notification {type} icon={true} showClose={false}>
  {#if title}
    <strong>{title}</strong><br />
  {/if}
  <slot />
  {#if error}
    {formatError(error)}
  {/if}
</Notification>
