<script>
  import { Icon, Button, Dialog } from 'svelma-fixed'
  import Tooltip from '../components/Tooltip.svelte'
  import { formatCurrency, generateRandomString, getAddressUrl } from '../lib/utils'
  import OpenSeaLogo from '../components/icons/OpenSeaLogo.svelte'
  import XLogo from '../components/icons/XLogo.svelte'
  import pluralize from 'pluralize'
  import ObjectsToCsv from 'objects-to-csv'
  import fileDownload from 'js-file-download'
  import { createLoadingStore } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import { router } from '../router'
  import FancyLoader from './FancyLoader.svelte'
  import Pagination from './Pagination.svelte'

  export let collection
  export let intermediate = false

  const PAGE_SIZE = 20
  let currentPage = 0

  const creatingOrder = createLoadingStore()

  async function buyFullList () {
    if (!intermediate) throw new Error('buyFullList can only be called on intermediate results')

    const email = await Dialog.prompt({
      title: 'Buy Full List',
      message: `For only <strong>${collection.fullResultPriceEth} ETH</strong> (≈${formatCurrency(collection.fullResultPriceUsd, 'USD')}), you can buy the full list of ${collection.totalOwnersCount.toLocaleString('en-US')} ${pluralize('token owner', collection.totalOwnersCount)}. You will pay through your ETH wallet.<br /><br />Please enter your email address to receive the full list:`,
      confirmText: 'Buy Now',
      cancelText: 'Cancel',
      showCancel: true,
      type: 'is-primary',
      inputProps: {
        type: 'email',
        placeholder: 'Email address'
      }
    })

    if (email === null) return

    try {
      await creatingOrder(async () => {
        const { id } = await apiCall('POST', '/api/orders', {
          nftContractAddress: collection.address,
          email
        })

        $router.push({ name: 'order', params: { orderId: id } })
      })
    } catch (e) {
      console.error(e)

      if (e.response?.status === 400) {
        await Dialog.alert({
          title: 'Error',
          message: e.serverErrorMessage,
          type: 'is-warning'
        })
      } else {
        await Dialog.alert({
          title: 'Error',
          message: 'An error occurred while creating your order. Please try again later.',
          type: 'is-danger'
        })
      }
    }
  }

  async function downloadCsv () {
    if (intermediate && collection.hasMoreItems) return buyFullList()

    const csv = await new ObjectsToCsv(collection.owners.map(owner => ({
      Address: owner.address,
      'Number of tokens': owner.tokens
    }))).toString()

    fileDownload(csv, `${collection.name} - Token Owners ${new Date().toISOString().slice(0, 10)}.csv`)
  }
</script>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .card.has-image {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), white min(18vw, 225px)), var(--bg-image);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, 100% auto;
  }

  .media-right :global(.icon) {
    vertical-align: middle;
  }

  @include mobile {
    .media {
      display: grid;
      grid-template-areas:
        "icon meta"
        "title title";
      grid-template-columns: 64px 1fr;
      gap: 0.5rem;

      .media-left {
        grid-area: icon;
      }

      .media-content {
        grid-area: title;
      }

      .media-right {
        grid-area: meta;
      }
    }
  }

  .owner-address {
    // Single-line with ellipsis
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(90vw - 240px);
  }

  .blurred-result {
    pointer-events: none;
    user-select: none;

    td:not(:first-child) {
      filter: blur(4px);
    }
  }

  .results.has-fake {
    position: relative;
  }

  .buy-overlay {
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
    text-align: center;
    height: 200px;
    padding-top: 30px;
    background: linear-gradient(to bottom, transparent, white);
  }
</style>

{#if $creatingOrder}
  <FancyLoader asHeader />
{:else}
  <div class="card" class:has-image={collection.bannerImageUrl} style:--bg-image='url("{collection.bannerImageUrl ?? 'about:blank'}"'>
    <div class="card-content">
      <div class="media">
        <div class="media-left">
          <figure class="image is-64x64">
            <img src="{collection.imageUrl ?? '/images/nft_placeholder.png'}" alt="Collection icon">
          </figure>
        </div>
        <div class="media-content">
          <p class="title is-3">
            {collection.name}
            {#if collection.verified}
              <Tooltip label="Verified collection">
                <span class="has-text-info" style:position="relative" style:top="-0.15em">
                  <Icon icon="check-circle" />
                </span>
              </Tooltip>
            {/if}
          </p>
          <p class="subtitle is-5">{collection.address}</p>
        </div>
        <div class="media-right has-text-right">
          <Tooltip label="View on Etherscan">
            <a href="{getAddressUrl(collection.address)}" target="_blank" rel="noopener noreferrer">
              <Icon icon="ethereum" pack="fab" />
            </a>
          </Tooltip>
          {#if collection.openSeaSlug}
            <Tooltip label="View on OpenSea">
              <a href="https://opensea.io/collection/{collection.openSeaSlug}" target="_blank" rel="noopener noreferrer">
                <span class="icon">
                  <OpenSeaLogo />
                </span>
              </a>
            </Tooltip>
          {/if}
          {#if collection.externalUrl}
            <Tooltip label="Website">
              <a href="{collection.externalUrl}" target="_blank" rel="noopener noreferrer">
                <Icon icon="external-link-alt" />
              </a>
            </Tooltip>
          {/if}
          {#if collection.twitterUsername}
            <Tooltip label="X profile">
              <a href="https://twitter.com/{collection.twitterUsername}" target="_blank" rel="noopener noreferrer">
                <span class="icon">
                  <XLogo />
                </span>
              </a>
            </Tooltip>
          {/if}
          {#if collection.discordUrl}
            <Tooltip label="Discord server">
              <a href="{collection.discordUrl}" target="_blank" rel="noopener noreferrer">
                <Icon icon="discord" pack="fab" />
              </a>
            </Tooltip>
          {/if}
          <br />
          {#if collection.totalSupply != null}
            Total supply: {collection.totalSupply.toLocaleString('en-US')}
          {/if}
        </div>
      </div>

      <div class="content pre-wrap">
        {#if collection.description}
          {collection.description.trim()}
        {:else}
          <i>No description</i>
        {/if}
      </div>

      {#if collection.totalOwnersCount}
        <div class="level">
          <div class="level-left">
            <h5 class="title is-5 level-item">
              <Icon icon="users" /> {collection.totalOwnersCount.toLocaleString('en-US')}{collection.totalIsMoreThanCount ? '+' : ''} {pluralize('token owner', collection.totalOwnersCount)} found!
            </h5>
          </div>
          <div class="level-right">
            <Button type="is-primary" iconLeft="file-excel" on:click={downloadCsv} class="level-item">Download CSV</Button>
          </div>
        </div>

        <div class="results" class:has-fake={intermediate && collection.hasMoreItems} >
          <table class="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th></th>
                <th>Address</th>
                <th class="has-text-right"># of tokens</th>
              </tr>
            </thead>
            <tbody>
              {#each collection.owners.slice(PAGE_SIZE * currentPage, PAGE_SIZE * (currentPage + 1)) as owner, i (owner.address)}
                <tr>
                  <td class="has-text-right">{i + PAGE_SIZE * currentPage + 1}.</td>
                  <td>
                    <a href="https://opensea.io/{owner.address}" target="_blank" rel="noopener noreferrer" class="owner-address">
                      {owner.address}
                    </a>
                  </td>
                  <td class="has-text-right">{owner.tokens.toLocaleString('en-US')}</td>
                </tr>
              {/each}

              {#if intermediate && collection.hasMoreItems}
                {#each [0, 1, 2, 3, 4] as i}
                  <tr class="blurred-result">
                    <td class="has-text-right">
                      {collection.owners.length + i + 1}.
                    </td>
                    <td>
                      <a href={undefined} class="owner-address">
                        0x{generateRandomString(40)}
                      </a>
                    </td>
                    <td class="has-text-right">
                      {String(Math.floor(Math.random() * 10)).repeat(String(collection.owners.at(-1)?.tokens ?? '0').length)}
                    </td>
                  </tr>
                {/each}
              {/if}
            </tbody>
          </table>

          {#if intermediate && collection.hasMoreItems}
            <div class="buy-overlay">
              <Button type="is-primary" iconLeft="eye" on:click={buyFullList}>Show all {collection.totalOwnersCount.toLocaleString('en-US')} {pluralize('result', collection.totalOwnersCount)}</Button>
            </div>
          {/if}
        </div>

        {#if !intermediate && collection.owners.length > PAGE_SIZE}
          <Pagination bind:currentPage maxPage={Math.ceil(collection.owners.length / PAGE_SIZE) - 1} />
        {/if}
      {:else}
        <h5 class="title is-5">
          <Icon icon="exclamation-triangle" /> No token owners found for this collection!
        </h5>
      {/if}
    </div>
  </div>
{/if}
