<script>
  import { disconnectAllWallets, connectWallet, walletConnecting, activeWalletAddress, ensureNetwork, sendTx, restoreWalletConnection } from '../stores/onboard'
  import { apiCall } from '../lib/api'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import uri from 'uri-tag'
  import FancyLoader from '../components/FancyLoader.svelte'
  import CollectionDetails from '../components/CollectionDetails.svelte'
  import { onDestroy, onMount } from 'svelte'
  import pluralize from 'pluralize'
  import { formatCurrency, getTxUrl } from '../lib/utils'
  import Loader from '../components/Loader.svelte'
  import { Button, Icon, Dialog } from 'svelma-fixed'
  import { createLoadingStore } from '../stores/loading'
  import html from 'html-template-tag'

  export let orderId
  let order

  const submitting = createLoadingStore()

  restoreWalletConnection()

  async function loadOrder () {
    order = await apiCall('GET', uri`/api/orders/${orderId}`)
  }

  let interval
  onMount(() => {
    interval = setInterval(async () => {
      try {
        if (['unpaid', 'confirming'].includes(order?.status) && !$submitting) {
          await loadOrder()
        }
      } catch (e) {
        console.error('Error updating order', e)
      }
    }, 5000)
  })

  onDestroy(() => {
    clearInterval(interval)
  })

  async function loadOrderResult () {
    return await apiCall('GET', uri`/api/orders/${order.id}/result`)
  }

  async function reconnectWallet () {
    await disconnectAllWallets()
    await connectWallet()
  }

  async function sendPayment () {
    await submitting(async () => {
      try {
        if (!order.paymentDestinationAddress) throw new Error('Internal error (no payment destination address)') // Avoid accidental contract deployments

        await ensureNetwork('mainnet')
        const walletAddress = $activeWalletAddress
        const txHash = await sendTx({
          from: walletAddress,
          to: order.paymentDestinationAddress,
          value: '0x' + (order.amountEth * 1e18).toString(16),
          data: order.paymentId
        })

        order = await apiCall('PATCH', uri`/api/orders/${order.id}`, { txHash, walletAddress })
      } catch (e) {
        console.error(e)
        if (e.code === 'ACTION_REJECTED' || e.code === 4001) return

        const errorMessage = e.message.includes('See {error}') ? e.error.message : e.message

        Dialog.alert({
          title: 'Error',
          message: html`
            <p>
              ${errorMessage}
            </p>
          `,
          type: 'is-danger'
        })
      }
    })
  }

  async function useCoupon () {
    if ($submitting) return

    const couponCode = await Dialog.prompt({
      title: 'Use Coupon',
      message: 'Please enter your coupon code:',
      confirmText: 'Submit',
      cancelText: 'Cancel',
      showCancel: true,
      type: 'is-primary',
      inputProps: {
        type: 'text',
        placeholder: 'Coupon code'
      }
    })

    if (!couponCode) return

    await submitting(async () => {
      try {
        order = await apiCall('PATCH', uri`/api/orders/${order.id}`, { couponCode })
      } catch (e) {
        console.error(e)

        const errorMessage = e.serverErrorMessage || e.message

        Dialog.alert({
          title: 'Error',
          message: html`
            <p>
              ${errorMessage}
            </p>
          `,
          type: 'is-danger'
        })
      }
    })
  }
</script>

<svelte:head>
	<title>Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h3 class="title is-3">Order # {orderId}</h3>

    {#await loadOrder()}
      <FancyLoader asHeader />
    {:then}
      <table class="table is-fullwidth">
        <tbody>
          <tr>
            <th scope="row">Order time:</th>
            <td>{new Date(order.createdAt).toLocaleString()}</td>
          </tr>
          <tr>
            <th scope="row">Collection:</th>
            <td>{order.collectionName} <small>({order.nftContractAddress})</small></td>
          </tr>
          <tr>
            <th scope="row">List length:</th>
            <td>{order.numberOfOwners.toLocaleString('en-US')} {pluralize('owner', order.numberOfOwners)}</td>
          </tr>
          <tr>
            <th scope="row">Amount:</th>
            <td>{order.amountEth} ETH (≈{formatCurrency(order.amountUsd, 'USD')})</td>
          </tr>
          <tr>
            <th scope="row">Email address:</th>
            <td>{order.email}</td>
          </tr>
          {#if order.txHash || order.couponUsed}
            <tr>
              <th scope="row">ETH TX hash:</th>
              <td>
                {#if order.txHash}
                  <a href={getTxUrl(order.txHash)} target="_blank">{order.txHash}</a>
                {:else}
                  <em>(Coupon used)</em>
                {/if}
              </td>
            </tr>
          {/if}
          <tr>
            <th scope="row">Status:</th>
            <td>
              {#if order.status === 'confirming'}
                <span class="tag is-info">Confirming</span>
              {:else if order.status === 'paid'}
                <span class="tag is-success">Paid</span>
              {:else if order.status === 'failed'}
                <span class="tag is-danger">Failed</span>
              {:else if order.status === 'unpaid'}
                <span class="tag is-warning">Awaiting payment</span>
              {:else if order.status === 'expired'}
                <span class="tag is-dark">Expired</span>
              {:else}
                <span class="tag is-dark">Unknown</span>
              {/if}
            </td>
        </tbody>
      </table>

      {#if order.status === 'unpaid'}
        {#if $activeWalletAddress}
          <p class="my-5 has-text-centered"><Icon icon="check-circle" /> Connected to wallet {$activeWalletAddress}</p>
          <p class="my-5 has-text-centered"><Button size="is-large" type="is-success" on:click={() => sendPayment()} loading={$walletConnecting || $submitting}>Pay {order.amountEth} ETH (≈{formatCurrency(order.amountUsd, 'USD')})</Button></p>
          <p class="has-text-centered"><small><a href={undefined} on:click={() => useCoupon()}><Icon icon="ticket-alt" /> Use a coupon code</a></small></p>
          <p class="has-text-centered"><small><a href={undefined} on:click={() => reconnectWallet()}>Wrong wallet? Click here to connect a different wallet.</a></small></p>
        {:else}
          <p class="my-5 has-text-centered"><Button size="is-large" type="is-success" on:click={() => connectWallet()} loading={$walletConnecting || $submitting}>Connect Wallet to Pay</Button></p>
          <p class="has-text-centered"><small><a href={undefined} on:click={() => useCoupon()}><Icon icon="ticket-alt" /> Use a coupon code</a></small></p>
        {/if}
      {:else if order.status === 'confirming'}
        <Loader borderless text="Confirming your payment..." />
      {:else if order.status === 'paid'}
        {#await loadOrderResult()}
          <FancyLoader asHeader />
        {:then collection}
          <CollectionDetails {collection} />

          <p class="mt-3"><small><strong>Something not right?</strong> Feel free to contact our support team at <a href="mailto:hello@tailor.network">hello@tailor.network</a>!</small></p>
        {:catch error}
          <ErrorDisplay {error} />
        {/await}
      {:else}
        <ErrorDisplay title="Order failed">
          <p>Oops, your order didn't complete successfully. Please try again later.</p>
          <p>If you already completed the payment or have other concerns, please reach out to our support team at <a href="mailto:hello@tailor.network">hello@tailor.network</a>!</p>
        </ErrorDisplay>
      {/if}
    {:catch error}
      <ErrorDisplay {error} />
    {/await}
  </div>
</div>
