<script>
  export let borderless = false
</script>

<div class="m-4 columns">
  <div class="column is-half is-offset-one-quarter">
    <div class="{borderless ? '' : 'box'} {$$props.class}">
      <slot />
    </div>
  </div>
</div>
