<script>
  export let text = 'Loading...'
  export let borderless = false

  import MiddleBox from './MiddleBox.svelte'
  import { Icon } from 'svelma-fixed'
</script>

<MiddleBox class="has-text-centered" {borderless}>
  <p class="m-3"><Icon icon="spinner" size="is-large" customClass="fa-pulse" /></p>
  <h2 class="subtitle">{text}</h2>
  <slot />
</MiddleBox>
