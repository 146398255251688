<svg
  version="1.0"
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 260.000000 260.000000"
  preserveAspectRatio="xMidYMid meet"
>
  <g
    transform="translate(0.000000,260.000000) scale(0.100000,-0.100000)"
    fill="currentColor"
    stroke="none"
  >
    <path
      d="M1167 2559 c-215 -21 -445 -111 -624 -246 -101 -76 -248 -235 -312
-337 -68 -109 -123 -237 -158 -368 -26 -97 -28 -115 -28 -313 0 -197 2 -216
27 -310 98 -366 330 -653 667 -827 58 -30 182 -74 276 -99 80 -21 111 -24 290
-24 185 0 208 2 306 28 219 56 404 158 566 311 286 270 424 636 392 1036 -49
606 -532 1092 -1144 1150 -121 11 -131 11 -258 -1z m-120 -596 c32 -43 65 -87
73 -100 38 -55 266 -353 271 -353 3 0 68 69 146 153 77 83 151 163 165 177 13
14 59 64 102 113 l79 87 64 0 c50 0 64 -3 61 -14 -2 -8 -59 -74 -128 -148
-120 -128 -291 -313 -378 -408 -23 -25 -42 -49 -42 -53 0 -4 142 -197 315
-428 173 -232 313 -424 311 -427 -2 -4 -108 -6 -235 -4 l-230 3 -193 258
c-106 142 -203 271 -215 287 l-22 29 -27 -30 c-14 -16 -52 -57 -83 -90 -31
-33 -139 -148 -239 -257 l-182 -196 -66 -4 c-36 -1 -68 0 -70 3 -3 5 120 140
436 479 119 127 160 175 160 184 0 4 -132 183 -293 399 -161 216 -297 398
-301 405 -6 9 43 12 228 12 l236 0 57 -77z"
    />
    <path
      d="M742 1912 c12 -17 248 -333 738 -987 l195 -260 103 -3 c56 -1 102 1
102 5 0 4 -56 82 -125 173 -212 282 -296 394 -560 748 l-257 342 -104 0 c-100
0 -104 -1 -92 -18z"
    />
  </g>
</svg>
